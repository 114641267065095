<script setup lang="ts">
import {
  RadioGroupIndicator,
  RadioGroupItem,
  type RadioGroupItemProps,
} from 'radix-vue'
import { Circle } from 'lucide-vue-next'
import { cn } from '@/lib/utils'

const props = defineProps<RadioGroupItemProps & { class?: string }>()
</script>

<template>
  <RadioGroupItem
    v-bind="props"
    :class="
      cn(
        'aspect-square h-4 w-4 rounded-full cursor-pointer flex justify-center items-center border border-slate-200 border-slate-900 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:border-slate-50',
        props.class,
      )
    "
  >
    <RadioGroupIndicator
      :class="cn('flex items-center justify-center', props.class)"
    >
      <Circle class="w-2.5 h-2.5 text-slate-950 dark:text-slate-50" />
    </RadioGroupIndicator>
  </RadioGroupItem>
</template>
