<template lang="pug">
div.p-5.rounded-lg.flex.flex-col.gap-6(
  v-auto-animate 
  class='min-w-[368px]' 
)
  // Title
  CommonInput(
    v-model='title'
    name='button_title'
    label='Button title'
    placeholder='Enter title'
    :required='true'
  )
  // Button type
  div
    .action-desc.font-medium When clicked
    RadioGroup.flex.flex-col.gap-3.mt-2(v-model:model-value="select")  
      .flex.items-center.gap-3
        RadioGroupItem(id="one" value="reply")
        Label(for="one") Reply 
      .flex.items-center.gap-3
        RadioGroupItem(id="two" value="open-page")
        Label(for="two") Open a Page
      .flex.items-center.gap-3
        RadioGroupItem(id="three" value="open-link")
        Label(for="three") Open external link
      .flex.items-center.gap-3
        RadioGroupItem(id="four" value="request-input")
        Label(for="four") Request input
  // Result of radio
  

  // Open a page
  CommonSelect(
    v-model='pageId'
    name='open_page'
    label='Select a page'
    placeholder='Select'
    :required='true'
    :options="pageOptions"
    v-if='select === "open-page"'
  )

  // Open a external link
  CommonInput(
    v-model='url'
    name='open_link'
    label='Link'
    placeholder='https://'
    :required='true'
    v-if='select === "open-link"'
  )
  // Request input
  PageScreenRequiredInput(
    v-model:label="customInput.label"
    v-model:placeholder="customInput.placeholder"
    v-if='select === "request-input"'
  )

  // Reply
  CommonInput(
    v-model='message'
    name='reply'
    label='Confirmation message'
    placeholder='Enter confirm message'
    :required='true'
    v-if='showThanks'
  )
  
  // Actions
  .flex.justify-between
    // Delete
    UButton.text-black.gap-2.w-max.border.bg-white(
      class='hover:bg-white/90'
      @click='removeButton'
      v-if='isEdit'
    )
      .font-semibold Delete
    // More
    .flex.gap-2.flex-row-reverse
      UButton.text-white.gap-2.w-max.border(
        class=''
        @click='doSave'
        :disabled='!saveAble'
      )
        .font-semibold Save button
      UButton.text-black.gap-2.w-max.border.bg-white(
        class='hover:bg-white/90'
        @click='$emit("cancel")'
      )
        .font-semibold Cancel



</template>

<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'
import { storeToRefs } from 'pinia'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { useToast } from '~~/components/ui/toast'
const UButton = defineAsyncComponent(
  () => import('@/components/ui/button/Button.vue'),
)

const props = defineProps<{
  selectedBtn?: IButtonAction
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'remove', btn: IButtonAction): void
  (e: 'add', btn: IButtonAction): void
  (e: 'edit', btn: IButtonAction): void
}>()

const { toast } = useToast()

const { pageOptions } = storeToRefs(useCurrentPages())

// Form to create link
const title = ref('')
// Reply message
const message = ref('')
// Open page select
const pageId = ref('')
// Open external link
const url = ref('')
// Open required input
const customInput = ref({
  label: '',
  placeholder: '',
})

const select = ref('reply')
const showThanks = computed(() =>
  ['request-input', 'reply'].includes(select.value),
)
const saveAble = computed(() => {
  switch (select.value) {
    // Case reply
    case 'reply':
      return title.value && message.value
    // Case open page
    case 'open-page':
      return title.value && pageId.value

    // Case open link
    case 'open-link':
      return title.value && url.value
    // Case custom input
    case 'request-input':
      return (
        title.value &&
        message.value &&
        customInput.value.label &&
        customInput.value.placeholder
      )
    default:
      return false
  }
})

const isEdit = computed(() => !!props.selectedBtn)

function bindingField() {
  if (!isEdit.value || !props.selectedBtn) return
  else {
    // Biding select
    select.value = props.selectedBtn!.type

    // Biding field
    title.value = props.selectedBtn.title
    message.value = props.selectedBtn?.message || ''
    customInput.value = props.selectedBtn?.custom_input || {
      label: '',
      placeholder: '',
    }
    pageId.value = (props.selectedBtn?.page_id as string) || ''

    url.value = props.selectedBtn.website_url || ''
  }
}

onMounted(bindingField)
watch(isEdit, () => bindingField)

function doSave() {
  let form: IButtonAction
  switch (select.value) {
    // Case reply
    case 'reply':
      form = {
        id: uuidv4(),
        title: title.value,
        message: message.value,
        type: 'reply',
      }
      isEdit.value ? emit('edit', form) : emit('add', form)
      emit('cancel')
      break
    // Case open page
    case 'open-page':
      form = {
        id: uuidv4(),
        title: title.value,
        page_id: pageId.value,
        type: 'open-page',
      }
      isEdit.value ? emit('edit', form) : emit('add', form)
      emit('cancel')
      break

    // Case open link
    case 'open-link':
      form = {
        id: uuidv4(),
        title: title.value,
        website_url: url.value,
        type: 'open-link',
      }
      isEdit.value ? emit('edit', form) : emit('add', form)
      emit('cancel')
      break

    // Case custom input
    case 'request-input':
      form = {
        id: uuidv4(),
        title: title.value,
        custom_input: customInput.value,
        message: message.value,
        type: 'request-input',
      }
      isEdit.value ? emit('edit', form) : emit('add', form)
      emit('cancel')
      break

    default:
      break
  }

  return
}

function removeButton() {
  emit('cancel')
  emit('remove', props.selectedBtn!)
}
</script>

<style scoped></style>
